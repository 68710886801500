<template>
  <div class="wrap padding-top-layout">
    <div class="wrap__block">
      <div class="wrap__block__header wrap__header">
        <div class="wrap__block__header__content">
          <h1 class="wrap-title">{{ $t('b_list_submited.title_application_status') }}</h1>
        </div>
      </div>
    </div>
    <div class="category-table main-table color-column-status-table mb-9 custom-table view-table">
      <div class="table-header-custome minus-top">
        <div class="table-header-custome-name">{{ $t('b_list_submited.tittle_list_product') }}</div>
      </div>
      <data-table
        :data-source="listSubmitGrid"
        :grid-columns="listProductColumn"
        :init-grid="initGrid"
        :rowFocus="1"
        :allowAddNew="false"
        :isShowToolbar="false"
        :showFocus="false"
        :isEmptySource="isEmptySource"
        :labelEmpty="labelEmpty"
        :isResizeCustome="false"
        class="list-submitted-data_table"
        @onHandlerSelecteFilterColumn="onHandlerSelecteFilterColumn"
      />
    </div>

    <div class="category-table main-table color-column-status-table custom-table view-table">
      <div class="table-header-custome minus-top">
        <div class="table-header-custome-name">{{ $t('b_list_submited.tittle_list_emission') }}</div>
      </div>
      <data-table
        :data-source="listEmissionsGrid"
        :grid-columns="listEmissionsColumn"
        :init-grid="initGridEmission"
        :rowFocus="1"
        :allowAddNew="false"
        :isShowToolbar="false"
        :showFocus="false"
        :isEmptySource="isEmptySourceEmissions"
        :labelEmpty="labelEmpty"
        :isResizeCustome="false"
        class="list-submitted-data_table"
        @onHandlerSelecteFilterColumn="onHandlerSelecteFilterColumn"
      />
    </div>

    <log-history-popup :dialog="logHistoryPopup" :historyLogList="historyLogList" @close="logHistoryPopup = false" />
    <comment-log-popup :dialog="commentPopup" :contents="commentHistoryList" @close="commentPopup = false" />
  </div>
</template>

<script>
import DataTable from '@/components/category/data-table';
import CommentLogPopup from '@/components/dialogs/log-confirm-popup';
import LogHistoryPopup from '@/components/dialogs/history-popup.vue';
import QuestionPopup from '@/components/dialogs/question-popup';

import { formatDateTimeDataTable } from '@/utils/datetimeFormat';
import { ROUTES } from '@/router/constants';
import { KEYS_CODE } from '@/constants/keyboard';
import { BLANK_ID } from '@/constants/registerData';
import { mapActions, mapState } from 'vuex';
import * as wjcCore from '@mescius/wijmo';
import { CollectionView } from '@mescius/wijmo';
import { CellMaker } from '@mescius/wijmo.grid.cellmaker';
import * as wjGrid from '@mescius/wijmo.grid';
import { getColorStatusById, getStatusNameById, getColorTextStatusById } from '@/utils/status';
import { formatValue, $_helper_isNumberType } from '@/concerns/newRegisterData/wijmo.helper';
import { getWidthOfDetailButton } from '@/utils/multiLanguage';
import { getWidthByTextContent, setMinMaxSizeColumns } from '@/utils/calcTextWidth';
import { getProductListCommentHistory } from '@/api/product';
import { getProductList, getLogProducts, actionEmissionsProductList, getLogEmissions } from '@/api/product/emissionsProduct';
import { STATUS_FIELD } from "@/constants/status";

export default {
  name: 'ListSubmitted',
  components: { DataTable, CommentLogPopup, LogHistoryPopup, QuestionPopup },
  data() {
    return {
      breadCrumb: [
        {
          text: this.$t('b_list_submited.hyperlink_home'),
          disabled: false,
          href: `${ROUTES.PRODUCTS_EMISSION}/${ROUTES.PRODUCT_LIST}`,
        },
        {
          text: this.$t('b_list_submited.label_application_status'),
          disabled: true,
          href: `${ROUTES.PRODUCTS_EMISSION}/${ROUTES.PRODUCTS_LIST_SUBMITTED}`,
        },
      ],
      listProductColumn: [],
      listEmissionsColumn: [],
      listSubmitGrid: null,
      listEmissionsGrid: null,
      flexGridProduct: null,
      flexGridEmissions: null,
      listSubmitData: [],
      listEmissionsSubmitData: [],
      dialog: false,
      logContents: [],
      showTooltip: false,
      commentPopup: false,
      logHistoryPopup: false,
      commentHistoryList: [],
      historyLogList: [],
      processClick: {
        isShowAction: false,
      },
      duration: '',
      listLayer: [],
      durationList: [],
      indexId: '',
      isEmptySource: true,
      isEmptySourceEmissions: false,
      labelEmpty: this.$tc('b_list_submited.label_empty'),
      selectedFilterColumn: null,
    };
  },
  async mounted() {
    await this.updateBreadCrumb(this.breadCrumb);
    await Promise.all([this.handleGetListSubmitData(), this.handleGetEmissionsList()]);
    this.defineTable();
  },
  computed: {
    ...mapState('commonApp', ['isExpand']),
    ...mapState('registerData', ['isFullScreen']),
    ...mapState('userData', ['currentUser']),
    isShowAction() {
      return this.processClick?.isShowAction;
    },
  },
  methods: {
    ...mapActions('commonApp', ['updateBreadCrumb', 'actionUpdateIsLoadingTable']),
    initGrid(grid) {
      grid.columnHeaders.rows.defaultSize = 68;
      document.addEventListener('keydown', (event) => {
        if (
          (event.metaKey || event.ctrlKey) &&
          [
            KEYS_CODE.DOWN_ARROW,
            KEYS_CODE.UP_ARROW,
            KEYS_CODE.LEFT_ARROW,
            KEYS_CODE.RIGHT_ARROW,
            KEYS_CODE.ENTER,
          ].includes(event.keyCode)
        ) {
          event.preventDefault();
        }
      });

      grid.hostElement.addEventListener(
        'keydown',
        (event) => {
          if (event.metaKey || event.ctrlKey) {
            if (event.keyCode === KEYS_CODE.DOWN_ARROW) {
              const currentSelection = grid.selection;
              const cellRange = new wjGrid.CellRange(grid.rows.length - 1, currentSelection.col);
              grid.selection = cellRange;

              // re-select after add more
              setTimeout(() => {
                grid.selection = cellRange;
              }, 200);
            } else if (event.keyCode === KEYS_CODE.UP_ARROW) {
              const currentSelection = grid.selection;
              grid.selection = new wjGrid.CellRange(0, currentSelection.col);
            } else if (event.keyCode === KEYS_CODE.RIGHT_ARROW) {
              const currentSelection = grid.selection;
              grid.selection = new wjGrid.CellRange(currentSelection.row, grid.columns.length - 1);
            } else if (event.keyCode === KEYS_CODE.LEFT_ARROW) {
              const currentSelection = grid.selection;
              grid.selection = new wjGrid.CellRange(currentSelection.row, 1);
            }
          }
        },
        false,
      );

      grid.onSelectionChanged(null);
      this.flexGridProduct = grid;
    },
    initGridEmission(grid) {
      grid.columnHeaders.rows.defaultSize = 68;
      document.addEventListener('keydown', (event) => {
        if (
          (event.metaKey || event.ctrlKey) &&
          [
            KEYS_CODE.DOWN_ARROW,
            KEYS_CODE.UP_ARROW,
            KEYS_CODE.LEFT_ARROW,
            KEYS_CODE.RIGHT_ARROW,
            KEYS_CODE.ENTER,
          ].includes(event.keyCode)
        ) {
          event.preventDefault();
        }
      });

      grid.hostElement.addEventListener(
        'keydown',
        (event) => {
          if (event.metaKey || event.ctrlKey) {
            if (event.keyCode === KEYS_CODE.DOWN_ARROW) {
              const currentSelection = grid.selection;
              const cellRange = new wjGrid.CellRange(grid.rows.length - 1, currentSelection.col);
              grid.selection = cellRange;

              // re-select after add more
              setTimeout(() => {
                grid.selection = cellRange;
              }, 200);
            } else if (event.keyCode === KEYS_CODE.UP_ARROW) {
              const currentSelection = grid.selection;
              grid.selection = new wjGrid.CellRange(0, currentSelection.col);
            } else if (event.keyCode === KEYS_CODE.RIGHT_ARROW) {
              const currentSelection = grid.selection;
              grid.selection = new wjGrid.CellRange(currentSelection.row, grid.columns.length - 1);
            } else if (event.keyCode === KEYS_CODE.LEFT_ARROW) {
              const currentSelection = grid.selection;
              grid.selection = new wjGrid.CellRange(currentSelection.row, 1);
            }
          }
        },
        false,
      );

      grid.onSelectionChanged(null);
      this.flexGridEmissions = grid;
    },
    async onIdClicking(rowData, isShowNote) {
      if (!rowData.item?.id) return;
      if(isShowNote) {
        this.$router.push({ path: `/product-emission/emissions-detail/${rowData.item.id}?type=submit` });
        return
      }
      this.$router.push({ path: `/product-emission/product-detail/${rowData.item.id}?type=submit` });
    },
    async onHistoryClicking(rowData, isEmision) {
      if (!rowData.item?.id) return;
      this.workflowDataId = rowData.item?.id;
      await this.handleGetHistoryChanged(rowData.item?.id, isEmision);
      this.logHistoryPopup = true;
      this.dialogCancelBtn = true;
    },
    async handleGetHistoryChanged(workflowDataId, isEmision) {
      const payload = {
        id: workflowDataId,
        contractor_id: this.currentUser?.user?.contractor_id
      };

      const listHistoryChanged = isEmision ? await getLogEmissions(payload) : await getLogProducts(payload);
      this.historyLogList = listHistoryChanged.data.map((listHistoryChanged) => {
        return {
          ...listHistoryChanged,
          date: formatDateTimeDataTable(listHistoryChanged.updated_at),
          name: listHistoryChanged.content,
          status: getStatusNameById(listHistoryChanged.status),
          color: getColorStatusById(listHistoryChanged.status),
        };
      });
    },
    async handleGetListSubmitData() {
      try {
        this.actionUpdateIsLoadingTable(true);
        const payload = {
          status: [
            STATUS_FIELD.STATUS_SUBMIT,
            STATUS_FIELD.STATUS_REPRENSENT_RETURN,
            STATUS_FIELD.STATUS_RETURN
          ]
        };
        const listSubmitResponse = await getProductList(payload);
        if (listSubmitResponse.data.length > 0) {
          this.listSubmitData = listSubmitResponse.data.map((listSubmitItem) => {
            return {
              ...listSubmitItem,
              data_id: listSubmitItem.data_id,
              id: listSubmitItem.id.toString().split(',').join(''),
              title: listSubmitItem.title,
              status: getStatusNameById(listSubmitItem.status),
              product_name: listSubmitItem.name,
              product_code: listSubmitItem.code,
              log_history: this.$t('b_list_submited.button_log_history'),
              date_submit: formatDateTimeDataTable(listSubmitItem.submitted_at),
              last_modified: formatDateTimeDataTable(listSubmitItem.updated_at),
              iconComment: listSubmitItem.comment_count > 0 ? require(`@/assets/icons/comment.svg`) : '',
              created_at: formatDateTimeDataTable(listSubmitItem.submitted_at?.submitted_at),
              approver: listSubmitItem.selected_approver?.name,
              approved_by: listSubmitItem.approved_by,
              scope_id: parseInt(listSubmitItem.scope),
              category_id: parseInt(listSubmitItem.category),
              duration_id: listSubmitItem.duration_id,
              approver_info: listSubmitItem.status === STATUS_FIELD.STATUS_SUBMIT ? listSubmitItem?.selected_approver?.name : listSubmitItem?.returner?.name,
              status_id: listSubmitItem.status,
              colorStatus: getColorTextStatusById(listSubmitItem.status),
            };
          });
        }
        this.listLayer = listSubmitResponse.layer;
        this.actionUpdateIsLoadingTable(false);
      } catch (err) {
        this.actionUpdateIsLoadingTable(false);
      }
    },
    initialView() {
      if (this.listSubmitGrid) {
        this.listSubmitData = this.listSubmitGrid.items.filter((item) => item.id && item.id !== BLANK_ID);
      }
      const formatItems = ['emission_total_product', 'emission_per_product'];
      this.listSubmitGrid = new CollectionView([...this.listSubmitData], {
        trackChanges: true,
        sortComparer: (a, b) => {
          if (!formatItems.includes(this.selectedFilterColumn)) return null;
          if ($_helper_isNumberType(a) && $_helper_isNumberType(b)) {
            const numA = Number(formatValue(a));
            const numB = Number(formatValue(b));
            return numA - numB;
          }
          return null;
        },
      });
      this.flexGridProduct.columnGroups = this.listColumnGrid(false);
      this.listSubmitGrid.currentItem = null;
      this.flexGridProduct.formatItem.addHandler((s, e) => {
        if (s.columns[e.col].binding === 'status' && s.cells.cellType === wjGrid.CellType.Cell) {
          let rowValue = s.rows[e.row]?._data;
          e.cell.classList.add(rowValue?.colorStatus);
        }
      });
      this.markUnreadRows(this.flexGridProduct, this.listSubmitGrid);
      this.isEmptySource = this.listSubmitGrid?.items?.length <= 0;
      setMinMaxSizeColumns(this.flexGridProduct, this.listSubmitData);
    },

    initialViewEmissions() {
      if (this.listEmissionsGrid) {
        this.listSubmitData = this.listEmissionsGrid.items.filter((item) => item.id && item.id !== BLANK_ID);
      }
      const formatItems = ['emission_total_product', 'emission_per_product'];
      this.listEmissionsGrid = new CollectionView([...this.listEmissionsSubmitData], {
        trackChanges: true,
        sortComparer: (a, b) => {
          if (!formatItems.includes(this.selectedFilterColumn)) return null;
          if ($_helper_isNumberType(a) && $_helper_isNumberType(b)) {
            const numA = Number(formatValue(a));
            const numB = Number(formatValue(b));
            return numA - numB;
          }
          return null;
        },
      });
      setMinMaxSizeColumns(this.flexGridEmissions, this.listEmissionsSubmitData);
      this.flexGridEmissions.columnGroups = this.listColumnGrid(true);
      this.listEmissionsGrid.currentItem = null;
      this.flexGridEmissions.formatItem.addHandler((s, e) => {
        if (s.columns[e.col].binding === 'status' && s.cells.cellType === wjGrid.CellType.Cell) {
          let rowValue = s.rows[e.row]?._data;
          e.cell.classList.add(rowValue?.colorStatus);
        }
      });
      this.markUnreadRows(this.flexGridEmissions, this.listEmissionsGrid);
      this.isEmptySourceEmissions = this.listEmissionsGrid?.items?.length <= 0;
    },
    markUnreadRows(grid, dataItems) {
      grid.formatItem.addHandler((handler, eventHandler) => {
        let accessRowIndex = eventHandler.row;
        let currentItem = dataItems.items[accessRowIndex];
        if (eventHandler.panel.cellType === wjGrid.CellType.RowHeader) {
          let currentItemUnread = currentItem?.['unread_flg'] || 0;
          if (currentItemUnread) {
            eventHandler.cell.classList.add('wj-has-unread-mark');
          }
        }
      });
    },

    listColumnGrid(isShow) {
      return [
        {
          header: '#',
          binding: 'id',
          allowSorting: false,
          isReadOnly: true,
          visible: false,
        },
        {
          header: ' ',
          binding: 'data_id',
          minWidth: getWidthOfDetailButton(),
          maxWidth: getWidthOfDetailButton(),
          allowSorting: false,
          isRequired: false,
          isReadOnly: true,
          cssClassAll: 'template-detail-header submitted-color',
          cellTemplate: CellMaker.makeButton({
            text: this.$t('b_list_submited.button_detail'),
            click: (event, context) => this.onIdClicking(context, isShow),
          }),
        },
        {
          header: this.$t('b_list_submited.table_status'),
          binding: 'status',
          minWidth: 100,
          maxWidth: 980,
          allowSorting: false,
          isRequired: false,
          isReadOnly: true,
          multiLine: true,
          wordWrap: true,
          cssClassAll: 'submitted-color',
        },
        {
          header: this.$t('b_list_submited.table_products_name'),
          binding: 'product_name',
          minWidth: 196,
          maxWidth: 980,
          allowSorting: false,
          isRequired: false,
          isReadOnly: true,
          multiLine: true,
          wordWrap: true,
          cssClassAll: 'submitted-color',
        },
        {
          header: this.$t('b_list_submited.table_product_code'),
          binding: 'product_code',
          minWidth: 196,
          maxWidth: 980,
          allowSorting: false,
          isRequired: false,
          isReadOnly: true,
          multiLine: true,
          wordWrap: true,
          cssClassAll: 'submitted-color',
        },
        {
          header: this.$t('56_pattern.table_remarks'),
          binding: 'note',
          minWidth: 165,
          maxWidth: 980,
          allowSorting: false,
          isRequired: false,
          isReadOnly: true,
          multiLine: true,
          wordWrap: true,
          visible: isShow,
          cssClassAll: 'submitted-color',
        },
        {
          header: this.$t('b_list_submited.table_created_at'),
          binding: 'created_at',
          minWidth: 165,
          maxWidth: 200,
          allowSorting: false,
          isRequired: false,
          isReadOnly: true,
          multiLine: true,
          wordWrap: true,
          cssClassAll: 'submitted-color',
        },
        {
          header: this.$t('b_list_submited.table_approver_info'),
          binding: 'approver_info',
          minWidth: getWidthByTextContent(this.$t('b_list_submited.table_approver_info')) + 20,
          maxWidth: 980,
          allowSorting: false,
          isRequired: false,
          isReadOnly: true,
          multiLine: true,
          wordWrap: true,
          cssClassAll: 'submitted-color',
        },
        {
          header: this.$t('list_submited.button_log_history'),
          binding: 'log_history',
          width: "*",
          minWidth: 150,
          allowSorting: false,
          isRequired: false,
          // maxWidth: getWidthByTextContent(this.$t('list_submited.button_log_history')),
          cssClassAll: 'submit-log show-log-btn',
          cellTemplate: CellMaker.makeButton({
            text: this.$t('b_list_submited.button_log_history'),
            click: (e, ctx) => this.onHistoryClicking(ctx, isShow),
          }),
        },
      ];
    },
    scrollToTop() {
      if (!this.flexGridProduct) {
        return;
      }

      let rc = this.flexGridProduct.cells.getCellBoundingRect(0, 0, true);
      this.flexGridProduct.scrollPosition = new wjcCore.Point(this.flexGridProduct.scrollPosition.x, -rc.top);
    },
    defineTable() {
      this.listProductColumn = this.listColumnGrid(false);
      this.listEmissionsColumn = this.listColumnGrid(true);
      this.initialView();
      this.initialViewEmissions();
    },
    onHandlerSelecteFilterColumn(column) {
      this.selectedFilterColumn = column;
    },
    async onCommentClicking(rowData) {
      if (!rowData.item?.id) return;
      await this.handleGetCommentHistory(rowData.item?.id);
      this.commentPopup = true;
      this.dialogCancelBtn = true;
    },
    async handleGetCommentHistory(workflowDataId) {
      const payload = {
        id: workflowDataId,
      };
      const commentList = await getProductListCommentHistory(payload);
      this.commentHistoryList = commentList.data.map((commentHistory) => {
        return {
          ...commentHistory,
          date: formatDateTimeDataTable(commentHistory.created_at),
          name: commentHistory.user.name,
          content: commentHistory.content,
        };
      });
    },
    async handleGetEmissionsList() {
      try {
        this.actionUpdateIsLoadingTable(true);
        const payload = {
          status: [
            STATUS_FIELD.STATUS_SUBMIT,
            STATUS_FIELD.STATUS_REPRENSENT_RETURN,
            STATUS_FIELD.STATUS_RETURN
          ]
        };
        const response = await actionEmissionsProductList(payload);

        this.listEmissionsSubmitData = response.data?.map(item => {
          return {
            ...item,
            data_id: item.id,
            status: getStatusNameById(item.status),
            colorStatus: getColorTextStatusById(item.status),
            log_history: this.$t('b_list_submited.button_log_history'),
            created_at: formatDateTimeDataTable(item?.submitted_at?.submitted_at),
            approver_info: item.status === STATUS_FIELD.STATUS_SUBMIT ? item?.selected_approver?.name : item?.returner?.name,
            product_name: item.name,
            product_code: item.code,
          }
        })

        this.actionUpdateIsLoadingTable(false);
      } catch (err) {
        this.actionUpdateIsLoadingTable(false);
      }
    }
  },
};
</script>

<style lang="scss">
.list-submitted-data_table {
  .template-detail-header {
    padding: 2px 4px !important;

    .wj-btn {
      display: none;
    }
  }

  .wrap-table {
    max-height: 230px !important;
  }

  .wj-flexgrid .wj-cell[aria-readonly]:not(.emission, .wj-state-multi-selected) {
    background: #f7f7f2 !important;
    color: #404d50 !important;
  }

  .wj-flexgrid .wj-cell[aria-readonly].template-detail-header {
    padding: 4px !important;
  }

  .wj-flexgrid .wj-cell[aria-readonly].submit-log {
    padding: 4px !important;
  }

  .wj-flexgrid .wj-cell[aria-readonly].wj-state-active:not(.emission, .wj-state-multi-selected) {
    background: $monoOffWhite !important;
  }

  .wj-flexgrid .wj-cell-maker {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    color: #404d50;
    // font-family: 'Source Han Sans JP';
    padding: 0;
  }

  .wj-cell {
    .wj-cell-maker {
      justify-content: center;
    }

    .wj-cell-maker:hover {
      color: #ffffff;
      background: #0072a3;
    }
  }

  .template-detail-header {
    .wj-cell-maker {
      // margin-top: 2px;
      font-style: normal;
      font-family: 'Source Han Sans';
      width: 100%;
      color: #948810 !important;
      position: unset !important;
      font-weight: 500;
      font-size: 13px;
      line-height: 20px;
      text-align: center;
      background: none;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      border: 1px solid #a4a184;
    }

    .wj-cell-maker:hover {
      color: #ffffff !important;
      background: #0072a3;

      button {
        color: #ffffff;
      }
    }
  }

  .hide-filter {
    .wj-btn {
      display: none;
    }
  }

  .submit-log.wj-cell {
    .wj-btn {
      display: none;
    }

    .wj-cell-maker {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 1px 6px 3px;
      background: #e4e6e7;
      font-family: 'Source Han Sans';
      border-radius: 4px;
      justify-content: center;
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 20px;
      text-align: center;
      color: #404d50 !important;
      min-width: 64px;
      min-height: 24px;
      position: unset !important;
    }

    .wj-cell-maker:hover {
      color: #ffffff !important;
      background: #0072a3;

      button {
        color: #ffffff;
      }
    }
  }

  .approval-pending {
    color: #0072a3;
  }

  .approval-send-back {
    color: #e45b58;
  }

  .approval-complete {
    color: #99a6a9;
  }

  // .wj-elem-collapse {
  //   position: absolute;
  //   width: 20px;
  //   height: 20px;
  //   left: 8px;
  //   top: 6px;
  //   background: rgba(121, 134, 134, 0.12);
  //   border-radius: 6px;
  //   &.wj-glyph-minus {
  //     color: $monoDark;
  //     border-top: 1px solid;
  //   }
  // }
  .line-height_text {
    .wj-cell-maker {
      font-weight: 500;
      font-size: 13px;
      line-height: 14px;
      text-align: center;
      color: $monoBlack;
    }
  }

  .wj-flexgrid {
    .wj-cell {
      &.disable-btn {
        .wj-cell-maker {
          padding: 0 !important;
          overflow: hidden;
          line-height: 16px;
          color: $monoBlack;

          .data-table-action {
            cursor: text !important;
            background-color: #c7d0d0 !important;
            width: 100%;
            height: 100%;

            &:hover {
              color: $monoBlack !important;
            }
          }

          &:hover {
            color: $monoWhite !important;
          }
        }
      }
    }
  }
}
</style>
<style scoped lang="scss">
.custom-table {
  @media (max-width: $tablet) {
    position: relative;
    padding-left: 20px !important;

    .table-header-custome {
      position: absolute; 
      left: 0;
    }

    .list-submitted-data_table {
      margin-top: 44px;
    }
  }
}

.table-header-custome {
  height: 44px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 40px;
  gap: 16px;
  background: #6b838f;

  &-name {
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.03em;
    color: $monoWhite;
  }
}

.wrap {
  width: 100%;
  height: 100%;

  .wrap__block__header__content {
    display: flex;
    align-items: center;

    // tooltip icon
    .helper-icon {
      width: 20px;
      height: 20px;
      display: inline-block;
      position: relative;

      img {
        z-index: 1;
      }

      &.tooltip {
        display: block;
        justify-content: left;
        background: $monoWhite;
        border-radius: 4px;
        color: $monoBlack;
        margin: 0 auto;
        font-size: 15px;
        padding: 10px;
        height: unset;
        // width: 315px;
        max-width: max-content;
        // position: absolute;
        right: unset;
        left: 0px;
        bottom: unset;
        z-index: 9999;
        box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
          0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
          0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
        position: fixed;
        width: 100%;
      }
    }
  }

  &__status {
    margin-bottom: 48px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
    padding-right: 40px;

    @media (max-width: 768px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 16px;
      padding-right: 0px;
    }

    &__item {
      display: flex;
      align-items: center;

      &__wrap {
        margin-left: 12px;

        p {
          color: $monoBlack;
          margin: 0;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 22px;
          letter-spacing: 0.05em;

          &:nth-child(1) {
            font-style: normal;
            font-weight: 700;
            font-size: 11px;
            line-height: 18px;
            letter-spacing: 0.03em;
          }
        }
      }
    }
  }

  &__header {
    @media (max-width: $tablet) {
      margin-left: 0px;
    }

    &--p1 {
      max-width: 700px;
    }

    p {
      margin: 0px;
      font-weight: 400;
      font-size: 22px;
      line-height: 36px;
      letter-spacing: 0.05em;
      color: $goldMid;

      @media (max-width: 768px) {
        font-size: 22px;
        line-height: 28px;
      }
    }

    p:last-child {
      padding-top: 48px;
      color: $monoBlack !important;
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 28px;
      letter-spacing: 0.05em;
    }
  }

  &__block {
    display: flex;
    padding: 0 0 40px;

    @media (max-width: 768px) {
      padding-left: 0px;
      flex-direction: column;
    }

    &__header {
      flex: 1 0;

      @media (max-width: 768px) {
        padding-top: 40px;
      }

      &__content {
        flex-direction: row;
      }

      span,
      .wrap-title {
        margin: 0 16px 0 0;
        font-weight: 400;
        font-size: 22px;
        line-height: 36px;
        letter-spacing: 0.05em;
        color: $goldMid;

        @media (max-width: 768px) {
          font-size: 22px;
          line-height: 28px;
          margin-right: 8px;
        }
      }
    }
  }

  .pull-down-action {
    position: fixed;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    min-width: 89px;
    background: $monoOffWhite;
    box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
      0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
      0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
    border-radius: 4px;
    cursor: pointer;

    &.show {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    &-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 7px 16px 9px;
      gap: 8px;
      min-width: 89px;
      height: 40px;

      &__label {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.03em;
        color: $monoBlack;
      }
    }
  }
}

@include desktop {
  .custom-table {
    padding-left: 0 !important;
  }

  .return {
    &__header {
      margin-top: unset;
      margin-left: 40px;
    }
  }

  .wrap__block {
    margin-left: 40px;
  }

  .wrap {
    .wrap__block__header__content {
      .helper-icon {
        &.tooltip {
          position: absolute;
          width: 400px;
          max-width: unset;
        }
      }
    }
  }
}
</style>